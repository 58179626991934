<template>
  <main class="app">
    <router-view />
  </main>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fefefe;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style-type: none;
}

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

textarea {
  resize: vertical;
}

input[type="checkbox"],
input[type="radio"] {
  display: none;
}

input[type="submit"],
input[type="button"],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.app {
  max-width: 820px;
  height: 100vh;
  height: 100dvh;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }

  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: auto;

  padding-bottom: env(safe-area-inset-bottom);
}
</style>

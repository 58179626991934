<template>
  <div class="top">
    <ul>
      <li ref="top">
        <LinkRoom
          to="/history"
          name="日本史"
          description="日本史"
          icon="/images/icon/society.png"
          >top</LinkRoom
        >
      </li>
      <li>
        <LinkRoom
          to="/compare"
          name="【比較用】日本史"
          description="gpt-3.5-turbo V.S. カスタマイズしたGPT-3.5"
          icon="/images/icon/compare.png"
          >about</LinkRoom
        >
      </li>
      <li>
        <LinkRoom
          to="/english"
          name="英語"
          description=""
          icon="/images/icon/english.png"
          :isDisabled="true"
        />
      </li>
      <li>
        <LinkRoom
          to="/japanese"
          name="国語"
          description=""
          icon="/images/icon/japanese.png"
          :isDisabled="true"
        />
      </li>
      <li>
        <LinkRoom
          to="/math-i"
          name="数学I"
          description=""
          icon="/images/icon/math.png"
          :isDisabled="true"
        />
      </li>
      <li>
        <LinkRoom
          to="/math-a"
          name="数学A"
          description=""
          icon="/images/icon/math.png"
          :isDisabled="true"
        />
      </li>
      <li>
        <LinkRoom
          to="/chemistry"
          name="化学"
          description=""
          icon="/images/icon/science.png"
          :isDisabled="true"
        />
      </li>
      <li>
        <LinkRoom
          to="/physics"
          name="物理"
          description=""
          icon="/images/icon/science.png"
          :isDisabled="true"
        />
      </li>
      <li>
        <LinkRoom
          to="/biology"
          name="生物"
          description=""
          icon="/images/icon/science.png"
          :isDisabled="true"
        />
      </li>
      <li>
        <LinkRoom
          to="/world-history"
          name="世界史"
          description=""
          icon="/images/icon/society.png"
          :isDisabled="true"
        />
      </li>
      <li>
        <LinkRoom
          to="/geography"
          name="地理"
          description=""
          icon="/images/icon/society.png"
          :isDisabled="true"
        />
      </li>
      <li>
        <LinkRoom
          to="/programing"
          name="プログラミング"
          description=""
          icon="/images/icon/programing.png"
          :isDisabled="true"
        />
      </li>
      <li>
        <LinkRoom
          to="/ai"
          name="AI"
          description=""
          icon="/images/icon/ai.png"
          :isDisabled="true"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import LinkRoom from "@/components/LinkRoom";

export default {
  name: "Top",
  components: {
    LinkRoom,
  },
  mounted() {
    this.$refs.top.scrollIntoView(true);
  },
};
</script>

<style lang="scss">
.top {
  height: 100vh;
  background-color: #f5f5f5;
}
</style>

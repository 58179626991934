<template>
  <router-link
    :to="to"
    class="LinkRoom"
    :class="isDisabled ? 'LinkRoom--disabled' : ''"
  >
    <img :src="icon" :alt="name" class="LinkRoom__icon" />
    <div class="LinkRoom__text">
      <p class="LinkRoom__name">{{ name }}</p>
      <p v-if="description" class="LinkRoom__description">{{ description }}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "LinkRoom",
  props: {
    to: String,
    name: String,
    description: String,
    icon: String,
    isDisabled: Boolean,
  },
};
</script>

<style lang="scss">
.LinkRoom {
  min-height: 82px;
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: #fff;

  &--disabled {
    background-color: #f5f5f5;
    pointer-events: none;
  }

  &__icon {
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__text {
    margin-left: 16px;
    color: #333;
    line-height: 1;
  }

  &__name {
    font-size: 16px;
    font-weight: bold;
  }

  &__description {
    margin-top: 8px;
    color: #999;
    font-size: 14px;
  }
}
</style>

<template>
  <header class="RoomNav">
    <router-link to="/" class="RoomNav__link">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
      >
        <path d="M400-80 0-480l400-400 56 57-343 343 343 343-56 57Z" />
      </svg>
    </router-link>
    <p class="RoomNav__name">{{ name }}</p>
  </header>
</template>

<script>
export default {
  name: "RoomNav",
  props: {
    name: String,
    description: String,
    icon: String,
    isDisabled: Boolean,
  },
};
</script>

<style lang="scss">
.RoomNav {
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  position: absolute;
  top: -1px;
  display: flex;
  align-items: center;

  background-color: rgba(122, 148, 191, 0.9);

  &__link {
    width: 24px;
    height: 24px;
    align-items: center;
    fill: #333;
  }

  &__name {
    margin-left: 8px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }
}
</style>

<template>
  <ul class="ChatAreaCompare">
    <li
      v-for="(chat, index) in chats"
      :key="index"
      class="ChatAreaCompare__item"
      :class="chat.isUser ? 'ChatAreaCompare__item--user' : ''"
      ref="lastChatItem"
    >
      <div class="ChatAreaCompare__compare">
        <p v-if="!chat.isUser && chat.message" class="ChatAreaCompare__message">
          <span>【gpt-3.5-turbo】</span><br /><br />
          {{ chat.message }}
        </p>
        <p
          v-if="!chat.isUser && chat.message2"
          class="ChatAreaCompare__message"
        >
          <span>【カスタマイズ】</span><br /><br />
          {{ chat.message2 }}
        </p>
        <p v-if="chat.isUser" class="ChatAreaCompare__message">
          {{ chat.message }}
        </p>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ChatAreaCompare",
  props: {
    chats: Array,
    thumbnail: String,
  },
  mounted() {
    this.scrollInto();
  },
  updated() {
    this.scrollInto();
  },
  methods: {
    scrollInto() {
      if (this.chats.length) {
        this.$refs.lastChatItem[this.chats.length - 1].scrollIntoView(false);
      }
    },
  },
};
</script>

<style lang="scss">
.ChatAreaCompare {
  max-height: calc(100vh - 48px);
  max-height: calc(100dvh - 48px);
  padding: 0 4px;
  overflow-x: hidden;
  overflow-y: scroll;

  &__item {
    display: flex;

    &:first-child {
      padding-top: 48px;
    }

    &:last-child {
      padding-bottom: 48px;
    }
  }

  &__item + &__item {
    margin-top: 8px;
  }

  &__thumbnail {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  &__compare {
    width: 100%;
    display: flex;
  }

  &__message {
    max-width: calc(50% - 8px);
    margin: 8px 0 0 8px;
    padding: 8px 12px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 8px;
    user-select: text;
    white-space: pre-line;
    background-color: #fff;
  }

  &__compare &__message + &__message {
    margin-right: 8px;
  }

  &__item--user &__message {
    min-width: auto;
    max-width: 260px;
    margin-left: auto;
    background: #6de67b;
    margin: 0 8px 0 auto;
  }
}
</style>

<template>
  <ul class="ChatArea">
    <li
      v-for="(chat, index) in chats"
      :key="index"
      class="ChatArea__item"
      :class="chat.isUser ? 'ChatArea__item--user' : ''"
      ref="lastChatItem"
    >
      <img
        v-if="!chat.isUser"
        :src="thumbnail ? thumbnail : 'https://dummyimage.com/120/200120/fff'"
        alt="サムネイル"
        class="ChatArea__thumbnail"
      />
      <p class="ChatArea__message">
        {{ chat.message }}
      </p>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ChatArea",
  props: {
    chats: Array,
    thumbnail: String,
  },
  mounted() {
    this.scrollInto();
  },
  updated() {
    this.scrollInto();
  },
  methods: {
    scrollInto() {
      if (this.chats.length) {
        this.$refs.lastChatItem[this.chats.length - 1].scrollIntoView(false);
      }
    },
  },
};
</script>

<style lang="scss">
.ChatArea {
  max-height: calc(100vh - 48px);
  max-height: calc(100dvh - 48px);
  padding: 0 4px;
  overflow-x: hidden;
  overflow-y: scroll;

  &__item {
    display: flex;

    &:first-child {
      padding-top: 48px;
    }

    &:last-child {
      padding-bottom: 48px;
    }
  }

  &__item + &__item {
    margin-top: 8px;
  }

  &__thumbnail {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  &__message {
    max-width: 260px;
    margin: 8px 0 0 8px;
    padding: 8px 12px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 8px;
    user-select: text;
    white-space: pre-line;
    background-color: #fff;
  }

  &__item--user &__message {
    background: #6de67b;
    margin: 0 8px 0 auto;
  }
}
</style>

import Vue from "vue";
import axios from "axios";

import App from "./App.vue";
import router from "./router";

Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

const DEFAULT_TITLE = "パーソナル家庭教師が生涯の学びをサポート";

router.afterEach((to) => {
  const title = to.meta.title
    ? `${to.meta.title} | ${DEFAULT_TITLE}`
    : DEFAULT_TITLE;
  document.title = title;
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

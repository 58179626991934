import Vue from "vue";
import Router from "vue-router";

import TopPage from "@/pages/Top";
import HistoryPage from "@/pages/History";
import ComparePage from "@/pages/Compare";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "top",
      component: TopPage,
      meta: { title: "教科を選択" },
    },
    {
      path: "/history",
      name: "history",
      component: HistoryPage,
      meta: { title: "日本史の学習" },
    },
    {
      path: "/compare",
      name: "compare",
      component: ComparePage,
      meta: { title: "日本史でgpt-3.5-turboとの比較" },
    },
  ],
});

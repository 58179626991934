<template>
  <div class="compare">
    <RoomNav name="【比較用】日本史" />

    <ChatAreaCompare :chats="chats" ref="chatArea" />

    <div class="TextArea">
      <textarea
        ref="textAreaInput"
        v-model="message"
        cols="30"
        :style="{ height: height }"
        class="TextArea__input"
      />
      <button
        @click="sendMessage"
        :disabled="!message"
        class="TextArea__button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="28"
          viewBox="0 -960 960 960"
          width="28"
        >
          <path d="M120-160v-245l302-75-302-77v-243l760 320-760 320Z" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import RoomNav from "@/components/RoomNav";
import ChatAreaCompare from "@/components/ChatAreaCompare";

const BASE_URL = "https://chat-gpt.7110.me/"; // 'http://localhost:8200'

export default {
  name: "Compare",
  data() {
    return {
      chats: [],
      message: "",
      height: "36px",
    };
  },
  components: {
    RoomNav,
    ChatAreaCompare,
  },
  mounted() {
    try {
      const chats = JSON.parse(localStorage.getItem("compare"));
      this.chats = chats?.length ? chats : [];
    } catch (error) {
      localStorage.removeItem("compare");
    }
  },
  methods: {
    sendMessage() {
      const message = this.message;
      if (this.message) {
        this.$set(this.chats, this.chats.length, {
          isUser: true,
          message: message,
        });

        const chatsCount = this.chats.length;

        this.$axios
          .post(`${BASE_URL}/history_default`, {
            message: message,
          })
          .then((response) => {
            const res = response.data;

            const obj = this.chats[chatsCount] ? this.chats[chatsCount] : {};
            const newObj = {
              ...obj,
              ...{
                isUser: false,
                message: res.answer,
              },
            };

            this.$set(this.chats, chatsCount, newObj);

            localStorage.setItem("compare", JSON.stringify(this.chats));
          })
          .catch((error) => {
            console.log(error);
            alert(
              "APIでエラーが発生しました。個人プランでChatGPTのAPIに制限があるため、すみませんが少しお時間を置いてから再度お試しください。"
            );
          });

        this.$axios
          .post(`${BASE_URL}/history`, {
            message: message,
          })
          .then((response) => {
            const res = response.data;

            const obj = this.chats[chatsCount] ? this.chats[chatsCount] : {};
            const newObj = {
              ...obj,
              ...{
                isUser: false,
                message2: res.answer,
              },
            };

            this.$set(this.chats, chatsCount, newObj);

            localStorage.setItem("compare", JSON.stringify(this.chats));
          })
          .catch((error) => {
            console.log(error);
            alert(
              "APIでエラーが発生しました。個人プランでChatGPTのAPIに制限があるため、すみませんが少しお時間を置いてから再度お試しください。"
            );
          });
      }

      this.message = "";
    },
    resizeTextAreaInput() {
      this.height = "auto";
      this.$nextTick(() => {
        const height = this.$refs.textAreaInput.scrollHeight - 8 * 2;
        this.height = `${height > 200 ? 200 : height}px`;
      });
    },
  },
  watch: {
    message() {
      this.resizeTextAreaInput();
    },
  },
};
</script>

<style lang="scss">
.compare {
  height: 100vh;
  height: 100dvh;

  position: relative;
  background-image: url("/images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  // overflow-x: hidden;
  // overflow-y: scroll;
}

.TextArea {
  min-height: 48px;
  padding: 8px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  background: #fff;

  &__input {
    width: 100%;
    height: 36px;
    padding: 8px 16px;
    font-size: 13px;
    border-radius: 18px;
    border: 1px solid #eee;
    background-color: #f5f5f5;
  }

  &__button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      fill: blue;
    }

    &:disabled {
      pointer-events: none;
      svg {
        fill: gray;
      }
    }
  }
}
</style>
